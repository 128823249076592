import React from 'react';
import './Nav.css';
import { Link } from 'react-router-dom';

class Nav extends React.Component {
    navSlide() {
        if (window.innerWidth > 768){
            return
        }

        const links = document.querySelectorAll('.nav-links li');
        links.forEach((link, index) => {
            if (link.style.animation) {
                link.style.animation = '';
            } else {
                link.style.animation = `navLinkFade 0.15s ease forwards ${index / (links.length * 2) + 0.25}s`;
            }
        });
    }
    
    navClose() {
        if (window.innerWidth > 768){
            return
        }
        
        const checkbox = document.querySelector('input');
        const links = document.querySelectorAll('.nav-links li');

        checkbox.checked = false;
        links.forEach((link, index) => {
            link.style.animation = '';
        });
    }

    render() {
        return (
            <nav>
                <h3 className='logo'>Pedro Coelho's Portfolio</h3>
                <input className='nav-button' type="checkbox" onClick={this.navSlide} />
                <ul className='nav-links'>
                    <li>
                        <Link to='/' onClick={this.navClose}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to='/about' onClick={this.navClose}>
                            About Me
                        </Link>
                    </li>
                    <li>
                        <Link to='/projects' onClick={this.navClose}>
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link to='/more' onClick={this.navClose}>
                            More
                        </Link>
                    </li>
                </ul>
                <div className='burger'>
                    <div className='line1'></div>
                    <div className='line2'></div>
                    <div className='line3'></div>
                </div>
            </nav>
        );
    }
}

export default Nav;
