import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/counter/counterSlice'
import tabBarReducer from './components/tabBar/tabBarSlice'
import searchBarReducer from './features/searchBar/searchBarSlice';
import redditReducer from './components/reddit/redditSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        tabBar: tabBarReducer,
        search: searchBarReducer,
        reddit: redditReducer,
    },
});