import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import './Post.css';

const Post = (props) => {
    return (
        <div className='post'>
            <a href={props.post.url} rel='noreferrer'>
                <p className='author'>{props.post.author}</p>
                <p className='title'>{props.post.title}</p>
                {props.post.previewType === 'image' && <img className='preview' src={props.post.previewUrl} alt='' />}
                {props.post.previewType === 'hosted:video' && (
                    <video className='preview' src={props.post.previewUrl} alt='' controls />
                )}
                <div className='comments'><FontAwesomeIcon icon={faCommentAlt} />{props.post.commentsCount}</div>
            </a>
        </div>
    );
};

export default Post;
