import './Home.css';
import { useDispatch } from 'react-redux';
import { setSelected } from '../tabBar/tabBarSlice';
import { useEffect } from 'react';

const Home = () => {
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSelected(0))
    }, [dispatch])

    return (
        <main id='home'>
            <section id='hero'>
                <div className='section-content'>
                    <h1>Welcome</h1>
                    <p>Enter my page and explore previous works</p>
                </div>
            </section>

            <div className='spacer layer1'></div>

            <section id='highlights'>
                <div className='section-content'>
                    <h2>Highlights</h2>
                    <p>My highlights</p>
                </div>
            </section>
        </main>
)};

export default Home