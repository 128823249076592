import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from '../nav/Nav';
import Home from '../home/Home'
import About from '../about/About';
import TabBar from '../tabBar/TabBar';
import Reddit from '../reddit/Reddit';

import './App.css';


function App() {
    return (
        <Router>
            <div className='App'>
                <Nav />
                <TabBar />
                <Switch>
                    <Route path='/reddit'>
                        <Reddit />
                    </Route>
                    <Route path='/about'>
                        <About />
                    </Route>
                    <Route path='/'>
                        <Home />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
