import { createSlice } from '@reduxjs/toolkit';

export const tabBarSlice = createSlice({
    name: 'tabBar', 
    initialState: {
        selected: 0
    },
    reducers: {
        setSelected: (state, action) => {
            state.selected = action.payload;
        },
    },
});
export const { setSelected } = tabBarSlice.actions;

export const selectSelected = state => state.tabBar.selected;

export default tabBarSlice.reducer;
