import { createSlice } from "@reduxjs/toolkit";

const redditSlice = createSlice({
    name: 'reddit',
    initialState: {
        subreddit: 'r/OnePiece',
        description: '',
        posts: []
    },
    reducers: {
        setSubreddit: (state, action) => {
            state.subreddit = action.payload;
        },
        setDescription: (state, action) => {
            state.description = action.payload;
        },
        setPosts: (state, action) => {
            state.posts = action.payload;
        }
    }
})


export const descriptionSelector = state => state.reddit.description;
export const postsSelector = state => state.reddit.posts;
export const subredditSelector = state => state.reddit.subreddit;

export const { setSubreddit, setDescription, setPosts } = redditSlice.actions;
export default redditSlice.reducer;


export const fetchPosts = () => (dispatch, state) => {
    async function fetchPostsAsync() {
        try {
            const response = await fetch (`https://www.reddit.com/${state().reddit.subreddit}.json?limit=5`);
            const json = await response.json()
            const children = json.data.children;
    
            const posts = children.map(c => ({
                author: c.data.author,
                url: `https://www.reddit.com${c.data.permalink}`,
                previewUrl: getPreviewUrl(c.data),
                previewType: c.data.post_hint,
                text: c.data.selftext,
                title: c.data.title,
                commentsCount: c.data.num_comments,
            }))
            dispatch(setPosts(posts));
        } catch (e) {
            console.log(e.message)
        }
        
    }
    fetchPostsAsync();
};

export const fetchAbout = () => (dispatch, state) => {
    async function fetchAboutAsync() {
        const response = await fetch(`https://www.reddit.com/${state().reddit.subreddit}/about.json`);
        const json = await response.json();
        const publicDescription = json.data.public_description;
        dispatch(setDescription(publicDescription));
    }
    fetchAboutAsync();
};

const getPreviewUrl = data => {
    if (data.is_video) {
        return data.media.reddit_video.fallback_url
    }
    return (data.preview && data.preview.enabled) ? data.url : null
}