import Post from '../post/Post'

const PostsList = props => {
    return (
        <div className='posts-list'>
            <ul>
                {props.posts.map((post, index) => <li key={index}><Post post={post} /></li> )}
            </ul>
        </div>
    )
}

export default PostsList;