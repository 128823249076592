import './About.css'
import { setSelected } from '../tabBar/tabBarSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const About = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSelected(1))
    }, [dispatch])

    return (
        <main id='about'>
            <h1>About</h1>
        </main>
)};

export default About