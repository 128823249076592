import { createSlice } from "@reduxjs/toolkit";

const searchBarSlice = createSlice({
    name: 'search',
    initialState: {
        term: ''
    },
    reducers: {
        setTerm: (state, action) => {
            state.term = action.payload
        }
    }
})

export const termSelector = (state) => state.search.term;
export const { setTerm } = searchBarSlice.actions;
export default searchBarSlice.reducer;