import { useEffect } from 'react';
import { setSelected } from '../tabBar/tabBarSlice';
import { useDispatch, useSelector } from 'react-redux';
//import { setTerm, termSelector } from '../../features/searchBar/searchBarSlice.js';
import {
    descriptionSelector,
    postsSelector,
    fetchPosts,
    setSubreddit,
    fetchAbout,
    subredditSelector,
} from './redditSlice';

//import SearchBar from '../../features/searchBar/SearchBar';
import PostsList from '../../features/postList/PostList';

import './Reddit.css';

const Reddit = () => {
    // const searchTerm = useSelector(termSelector);
    const description = useSelector(descriptionSelector);
    const posts = useSelector(postsSelector);
    const subreddit = useSelector(subredditSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelected(2));
        dispatch(fetchAbout());
        dispatch(fetchPosts());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchAbout());
        dispatch(fetchPosts());
    }, [dispatch, subreddit]);

    // const handleChange = (e) => {
    //     dispatch(setTerm(e.target.value));
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     console.log(searchTerm);
    // };

    return (
        <main id='reddit'>
            <div className='content'>
                <div className='left'>
                    <PostsList className='postsList' posts={posts} />
                    <PostsList className='postsList' posts={posts} />
                </div>
                <div className='right'>
                    <div className='header'>
                        {/* <SearchBar
                            className='searchBar'
                            searchTerm={searchTerm}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                        />
                        <h1 className='title'>
                            r/<span>OnePiece</span>
                        </h1> */}

                        <div className='subreddit-buttons'>
                            <button
                                onClick={() => dispatch(setSubreddit('r/OnePiece'))}
                                className={subreddit === 'r/OnePiece' ? 'selected' : ''}
                            >
                                r/OnePiece
                            </button>
                            <button
                                onClick={() => dispatch(setSubreddit('r/ffxiv'))}
                                className={subreddit === 'r/ffxiv' ? 'selected' : ''}
                            >
                                r/ffxiv
                            </button>
                            <button
                                onClick={() => dispatch(setSubreddit('r/WebDev'))}
                                className={subreddit === 'r/WebDev' ? 'selected' : ''}
                            >
                                r/WebDev
                            </button>
                        </div>
                        <p className='description'>{description}</p>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Reddit;
