import { useEffect } from 'react';
import './TabBar.css';
import { Link } from 'react-router-dom';
import { selectSelected } from './tabBarSlice';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons';

const TabBar = () => {
    const selected = useSelector(selectSelected);

    function repositionMarker() {
        const activeItem = document.querySelector('.active');
        const menu = document.querySelector('.tab-bar');
        const menuBorder = menu.querySelector('.menu-border');
        const menuItems = document.querySelectorAll('.menu-item');

        if (activeItem) {
            activeItem.classList.remove('active');
        }

        menuItems[selected].classList.add('active');

        let animationTimeout;
        function offsetMenuBorder(element, menu, menuBorder) {
            const offsetActiveItem = element.getBoundingClientRect();
            const left =
                Math.floor(
                    offsetActiveItem.left - menu.offsetLeft - (menuBorder.offsetWidth - offsetActiveItem.width) / 2
                ) + 'px';
            menuBorder.style.transform = `translate3d(${left}, 0 , 0)`;

            if (animationTimeout && menuBorder.classList.contains('transition')) {
                clearTimeout(animationTimeout);
            }

            menuBorder.classList.add('transition');
            animationTimeout = setTimeout(() => {
                menuBorder.classList.remove('transition');
            }, 350);
        }

        offsetMenuBorder(menuItems[selected], menu, menuBorder);
    }

    // useEffect(() => {
    //     window.addEventListener('resize', repositionMarker); // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    useEffect(repositionMarker, [selected]);

    return (
        <div className='tab-bar'>
            <Link to='/' className='menu-item' style={{ '--iconColor': 'var(--nord15)' }}>
                <svg className='icon' viewBox='0 0 24 24'>
                    <path d='M3.8,6.6h16.4' />
                    <path d='M20.2,12.1H3.8' />
                    <path d='M3.8,17.5h16.4' />
                </svg>
            </Link>

            <Link to='/about' className='menu-item' style={{ '--iconColor': 'var(--nord14)' }}>
                <svg className='icon' viewBox='0 0 24 24'>
                    <path
                        d='M6.7,4.8h10.7c0.3,0,0.6,0.2,0.7,0.5l2.8,7.3c0,0.1,0,0.2,0,0.3v5.6c0,0.4-0.4,0.8-0.8,0.8H3.8
                    C3.4,19.3,3,19,3,18.5v-5.6c0-0.1,0-0.2,0.1-0.3L6,5.3C6.1,5,6.4,4.8,6.7,4.8z'
                    />
                    <path d='M3.4,12.9H8l1.6,2.8h4.9l1.5-2.8h4.6' />
                </svg>
            </Link>

            <Link to='/reddit' className='menu-item' style={{ '--iconColor': 'var(--nord11)' }}>
                <svg className='icon' viewBox='0 0 24 24'>
                    <FontAwesomeIcon icon={faRedditAlien} />
                </svg>
            </Link>

            <Link to='/' className='menu-item' style={{ '--iconColor': 'var(--nord12)' }}>
                <svg className='icon' viewBox='0 0 24 24'>
                    <path
                        d='M5.1,3.9h13.9c0.6,0,1.2,0.5,1.2,1.2v13.9c0,0.6-0.5,1.2-1.2,1.2H5.1c-0.6,0-1.2-0.5-1.2-1.2V5.1
                    C3.9,4.4,4.4,3.9,5.1,3.9z'
                    />
                    <path d='M4.2,9.3h15.6' />
                    <path d='M9.1,9.5v10.3' />
                </svg>
            </Link>

            <Link to='/' className='menu-item' style={{ '--iconColor': 'var(--nord11)' }}>
                <svg className='icon' viewBox='0 0 24 24'>
                    <path
                        d='M5.1,3.9h13.9c0.6,0,1.2,0.5,1.2,1.2v13.9c0,0.6-0.5,1.2-1.2,1.2H5.1c-0.6,0-1.2-0.5-1.2-1.2V5.1
                    C3.9,4.4,4.4,3.9,5.1,3.9z'
                    />
                    <path d='M5.5,20l9.9-9.9l4.7,4.7' />
                    <path d='M10.4,8.8c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C7.3,8,8,7.3,8.9,7.3C9.7,7.3,10.4,8,10.4,8.8z' />
                </svg>
            </Link>

            <div className='menu-border'></div>

            <div className='svg-container'>
                <svg viewBox='0 0 202.9 45.5'>
                    <clipPath
                        id='menu'
                        clipPathUnits='objectBoundingBox'
                        transform='scale(0.0049285362247413 0.021978021978022)'
                    >
                        <path
                            d='M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
                    c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
                    c9.2,3.6,17.6,4.2,23.3,4H6.7z'
                        />
                    </clipPath>
                </svg>
            </div>
        </div>
    );
};

export default TabBar;
